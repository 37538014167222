<template>
    <div class="overlay first"></div>
    <div class="overlay second"></div>
    <div class="overlay third"></div>
    <div class="wrapper">
        <div>
            <div class="header-container home">
                <div class="container h-100">
                    <div class="row header-wrapper h-100">
                        <div class="col-xl-7">
                            <div class="content-placeholder">
                                <div class="content position-relative">
                                    <h1 class="heading-text mb-4"
                                        data-aos="fade-up"
                                        data-aos-delay="2200"
                                        data-aos-duration="700"
                                        data-aos-once="true"
                                        data-aos-easing="ease-out-quart">
                                        {{ this.trans('PAGES.HOME.HEADER.TITLE_BEFORE_SPAN') }} <span
                                        class="text-green font-black">{{
                                            this.trans("PAGES.HOME.HEADER.TITLE_SPAN")
                                        }}</span>
                                        {{ this.trans("PAGES.HOME.HEADER.TITLE_AFTER_SPAN") }}
                                    </h1>
                                    <p class="heading-description-text"
                                       data-aos="fade-up"
                                       data-aos-duration="700"
                                       data-aos-delay="2400"
                                       data-aos-once="true"
                                       data-aos-easing="ease-out-quart">
                                        {{ this.trans("PAGES.HOME.HEADER.DESCRIPTION") }}
                                    </p>

                                    <div class="arrow-down" @click="scrollToSection('about-global')"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 offset-xl-1">
                            <div class="content-placeholder">
                                <div class="heading-img-wrapper">
                                    <img class="heading-img w-100" src="../assets/images/heading-home-img.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="heading-gradient home"></div>
            <main class="page home">
                <section class="about-global" ref="about-global">
                    <div class="container text-center">
                        <div class="row">
                            <h2 class="heading-text text-center text-green mb-3">
                                {{ this.trans("PAGES.HOME.SECTIONS.ABOUT.TITLE") }}
                            </h2>
                            <p class="heading-description-text text-center w-100">
                                {{ this.trans("PAGES.HOME.SECTIONS.ABOUT.DESCRIPTION") }}
                            </p>
                            <router-link style="width: auto" class="m-auto" :to="`/${this.$locale}/about`">
                                <div class="link-button mt-4">
                                    <div class="color-fill">
                                        <img class="arrow-icon" src="../assets/icons/arrow.svg" alt="">
                                    </div>
                                    <p class="link-text">{{ this.trans("BUTTONS.LEARN_MORE") }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="container-fluid text-center mt-5 p-0">
                        <div class="carousel">
                            <div class="card-item">
                                <img src="../assets/images/carousel/carousel-1.png" alt="" class="card-image w-100">
                                <p class="card-text">Jervois Hill Singapore</p>
                            </div>
                            <div class="card-item">
                                <img src="../assets/images/carousel/carousel-2.png" alt="" class="card-image w-100">
                                <p class="card-text">Gallop Park Singapore</p>
                            </div>
                            <div class="card-item">
                                <img src="../assets/images/carousel/carousel-3.png" alt="" class="card-image w-100">
                                <p class="card-text">Vue Restaurant Singapore</p>
                            </div>
                            <div class="card-item">
                                <img src="../assets/images/carousel/carousel-4.png" alt="" class="card-image w-100">
                                <p class="card-text">Indigo Singapore</p>
                            </div>
                            <div class="card-item">
                                <img src="../assets/images/carousel/carousel-5.png" alt="" class="card-image w-100">
                                <p class="card-text">Four Season, Desroches Seychelles</p>
                            </div>
                            <div class="card-item">
                                <img src="../assets/images/carousel/carousel-6.png" alt="" class="card-image w-100">
                                <p class="card-text">Latitude Singapore</p>
                            </div>
                            <div class="card-item">
                                <img src="../assets/images/carousel/carousel-7.png" alt="" class="card-image w-100">
                                <p class="card-text">Latitude Singapore</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="why-casa p-0">
                    <div class="container pt-5 pb-5">
                        <div class="row">
                            <h2 class="section-heading-text mb-5 mt-5">
                                {{ this.trans("PAGES.HOME.SECTIONS.WHY_CASA.TITLE") }}
                            </h2>
                            <div class="col-lg-5">
                                <div class="content-box mb-5">
                                    <div class="left-part">
                                        <img src="../assets/icons/chat.svg" class="box-icon" alt="">
                                    </div>
                                    <div class="right-part">
                                        <h6 class="box-heading-text">
                                            {{ this.trans("PAGES.HOME.SECTIONS.WHY_CASA.OPTIONS.OPTION_1.TITLE") }}
                                        </h6>
                                        <p class="box-description-text mt-3 mb-5"
                                           data-aos="zoom-in-right"
                                           data-aos-duration="600"
                                           data-aos-delay="400"
                                           data-aos-once="true"
                                           data-aos-easing="ease-out-quart">
                                            {{
                                                this.trans("PAGES.HOME.SECTIONS.WHY_CASA.OPTIONS.OPTION_1.DESCRIPTION")
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="content-box mb-5">
                                    <div class="left-part">
                                        <img src="../assets/icons/housing.svg" class="box-icon" alt="">
                                    </div>
                                    <div class="right-part">
                                        <h6 class="box-heading-text">
                                            {{ this.trans("PAGES.HOME.SECTIONS.WHY_CASA.OPTIONS.OPTION_2.TITLE") }}
                                        </h6>
                                        <p class="box-description-text mt-3 mb-5"
                                           data-aos="zoom-in-right"
                                           data-aos-duration="600"
                                           data-aos-delay="800"
                                           data-aos-once="true"
                                           data-aos-easing="ease-out-quart">
                                            {{
                                                this.trans("PAGES.HOME.SECTIONS.WHY_CASA.OPTIONS.OPTION_2.DESCRIPTION")
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="content-box mb-5">
                                    <div class="left-part">
                                        <img src="../assets/icons/voice.svg" class="box-icon" alt="">
                                    </div>
                                    <div class="right-part">
                                        <h6 class="box-heading-text">
                                            {{ this.trans("PAGES.HOME.SECTIONS.WHY_CASA.OPTIONS.OPTION_3.TITLE") }}
                                        </h6>
                                        <p class="box-description-text mt-3 mb-5"
                                           data-aos="zoom-in-right"
                                           data-aos-duration="600"
                                           data-aos-delay="1200"
                                           data-aos-once="true"
                                           data-aos-easing="ease-out-quart">
                                            {{
                                                this.trans("PAGES.HOME.SECTIONS.WHY_CASA.OPTIONS.OPTION_3.DESCRIPTION")
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <router-link style="width: auto" class="m-auto" :to="`/${this.$locale}/contact`">
                                    <div class="link-button mb-5">
                                        <div class="color-fill">
                                            <img class="arrow-icon" src="../assets/icons/arrow.svg" alt="">
                                        </div>
                                        <p class="link-text text-white">
                                            {{ this.trans("BUTTONS.CONTACT_US") }}
                                        </p>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-6 offset-lg-1 position-relative">
                                <img class="section-main-img d-none d-lg-block"
                                     src="../assets/images/section-why-casa-bg.png"
                                     alt="">
                            </div>
                        </div>
                    </div>
                </section>
                <section class="casa-global">
                    <div class="container">
                        <div class="row">
                            <h2 class="heading-text text-center text-green mt-5 mb-3 pt-3"
                                data-aos="fade-up"
                                data-aos-duration="700"
                                data-aos-once="true"
                                data-aos-easing="ease-out-quart">
                                {{ this.trans("PAGES.HOME.SECTIONS.CASA_GLOBAL.TITLE") }}
                            </h2>
                            <p class="heading-description-text text-center m-auto"
                               data-aos="fade-up"
                               data-aos-duration="700"
                               data-aos-delay="500"
                               data-aos-once="true"
                               data-aos-easing="ease-out-quart">
                                {{ this.trans("PAGES.HOME.SECTIONS.CASA_GLOBAL.DESCRIPTION") }}
                            </p>
                            <div class="vector-map position-relative">
                                <img src="../assets/vectors/map.svg" class="w-100 mt-5 pt-4" alt="Map">
                                <div class="pin singapore"
                                     data-aos="fade-up"
                                     data-aos-duration="400"
                                     data-aos-delay="1500"
                                     data-aos-once="true"
                                     data-aos-easing="ease-out-quart"></div>
                                <div class="pin macedonia"
                                     data-aos="fade-up"
                                     data-aos-duration="400"
                                     data-aos-delay="1000"
                                     data-aos-once="true"
                                     data-aos-easing="ease-out-quart"></div>
                                <div class="pin italy"
                                     data-aos="fade-up"
                                     data-aos-duration="400"
                                     data-aos-delay="500"
                                     data-aos-once="true"
                                     data-aos-easing="ease-out-quart"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="services">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-5">
                                <h2 class="heading-text mb-3"
                                    data-aos="fade-up"
                                    data-aos-duration="700"
                                    data-aos-once="true"
                                    data-aos-easing="ease-out-quart">
                                    {{ this.trans("PAGES.HOME.SECTIONS.SERVICES.TITLE") }}
                                </h2>
                                <p class="section-text"
                                   data-aos="fade-up"
                                   data-aos-duration="700"
                                   data-aos-delay="500"
                                   data-aos-once="true"
                                   data-aos-easing="ease-out-quart">
                                    {{ this.trans("PAGES.HOME.SECTIONS.SERVICES.DESCRIPTION") }}
                                </p>
                                <router-link style="width: auto" class="m-auto" :to="`/${this.$locale}/services`">
                                    <div class="link-button mt-4">
                                        <div class="color-fill">
                                            <img class="arrow-icon" src="../assets/icons/arrow.svg" alt="">
                                        </div>
                                        <p class="link-text">{{ this.trans("BUTTONS.LEARN_MORE_SERVICES") }}</p>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-xl-7 position-relative">
                                <img src="../assets/images/home-services-bg.png" alt="" class="section-img">
                            </div>
                        </div>
                    </div>
                </section>
                <section class="contact-box">
                    <div class="container color-container">
                        <div class="row">
                            <div class="text-container col-lg-6">
                                <h3 class="section-heading-text text-white mb-3">
                                    {{ this.trans("PAGES.HOME.SECTIONS.CONTACT.TITLE") }}
                                </h3>
                                <p class="section-description-text text-white m-0">
                                    {{ this.trans("PAGES.HOME.SECTIONS.CONTACT.DESCRIPTION") }}
                                </p>
                            </div>
                            <div class="col-lg-5 offset-lg-1">
                                <div class="content-placeholder">
                                    <router-link style="width: auto" class="ml-auto" :to="`/${this.$locale}/contact`">
                                        <div class="link-button green mb-5">
                                            <div class="color-fill">
                                                <img class="arrow-icon" src="../assets/icons/arrow.svg" alt="">
                                            </div>
                                            <p class="link-text text-white">{{ this.trans("BUTTONS.CONTACT_US") }}</p>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <img class="abstract-house d-lg-block d-none" src="../assets/images/house-drawing.png" alt="">
                    </div>
                </section>
            </main>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import {gsap} from "gsap";
import {EasePack} from "gsap/EasePack";
import TweenMax, {CSSRulePlugin} from "gsap/all";

gsap.registerPlugin(EasePack, CSSRulePlugin);

let arrowDown = CSSRulePlugin.getRule('.header-container.home .content-placeholder .content::after')
let lines = CSSRulePlugin.getRule('.header-container.home .content-placeholder .heading-img-wrapper::before')

export default {
    name: 'Home',
    mounted() {
        TweenMax.set('.first, .second, .third', {
            left: '0%',
        })

        TweenMax.set(lines, {
            opacity: 0
        })

        TweenMax.set('.header-container.home .content-placeholder .heading-img-wrapper .heading-img', {
            opacity: 0
        })

        TweenMax.from('#nav .nav-inside', 1.5, {
            delay: 1.2,
            y: '-150%',
            ease: "Expo.easeOut"
        })

        TweenMax.to('.header-container.home .content-placeholder .heading-img-wrapper .heading-img', 1.5, {
            delay: 1.4,
            opacity: 1,
            ease: "Expo.easeInOut"
        })

        TweenMax.to(lines, 2, {
            delay: 1.6,
            opacity: 1,
            ease: "Expo.easeInOut"
        })

        TweenMax.from(arrowDown, 1.5, {
            delay: 1.8,
            opacity: 0,
            ease: "Expo.easeOut"
        })

        TweenMax.to('.heading-text', 1, {
            delay: 2.2,
            width: '100%',
            ease: "Expo.easeInOut"
        })

        TweenMax.to('.first', 1.5, {
            delay: .2,
            left: '-100%',
            ease: "Expo.easeInOut"
        })

        TweenMax.to('.second', 1.5, {
            delay: .4,
            left: '-100%',
            ease: "Expo.easeInOut"
        })

        TweenMax.to('.third', 1.5, {
            delay: .6,
            left: '-100%',
            ease: "Expo.easeInOut"
        })

        $(".lang-link").removeClass("router-link-active");
        $(".lang-link." + localStorage.selectedLanguage).addClass("router-link-active");
        this.$root.setTitle(this.trans('NAV_ITEMS.HOME'))
        window.scrollTo(0, 0)
        $('.carousel').slick({
            centerMode: true,
            dots: false,
            infinite: true,
            speed: 800,
            slidesToShow: 4,
            swipeToSlide: true,
            autoplay: true,
            cssEase: 'cubic-bezier(0.65, 0, 0.35, 1)',
            autoplaySpeed: 3000,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '50px',
                    }
                },
            ]
        });
    },
    beforeUnmount() {
        $(".carousel").slick("unslick")
    },
    methods: {
        scrollToSection(section) {
            let element = this.$refs[section];
            let top = element.offsetTop;

            window.scrollTo(0, top)
        }
    }
}
</script>

<style scoped>

</style>