<template>
    <div>
        <div class="header-container about">
            <div class="container">
                <div class="row">
                    <div class="content-placeholder">
                        <div class="content col-xxl-8 col-xl-7 col-12">
                            <h1 class="heading-text mb-4"
                                data-aos="fade-up"
                                data-aos-duration="700"
                                data-aos-once="true"
                                data-aos-easing="ease-out-quart">
                                <span class="text-green font-black">{{
                                        this.trans("PAGES.ABOUT.HEADER.TITLE_GREEN_SPAN")
                                    }}</span> {{ this.trans("PAGES.ABOUT.HEADER.TITLE_AFTER_GREEN_SPAN") }} <br/>
                                {{ this.trans("PAGES.ABOUT.HEADER.TITLE_BEFORE_BROWN_SPAN") }}
                                <span
                                        class="text-brown font-black">{{
                                        this.trans("PAGES.ABOUT.HEADER.TITLE_BROWN_SPAN")
                                    }} </span>
                            </h1>
                            <p class="heading-description-text"
                               data-aos="fade-up"
                               data-aos-duration="700"
                               data-aos-delay="500"
                               data-aos-once="true"
                               data-aos-easing="ease-out-quart">
                                {{ this.trans("PAGES.ABOUT.HEADER.DESCRIPTION") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main class="page about pt-5 pb-5">
            <section class="main-about-image">
                <div class="container">
                    <div class="row">
                        <img src="../assets/images/about-section-big-img.png" class="w-100" alt="">
                    </div>
                </div>
            </section>
            <section class="about-company">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 left-col">
                            <div>
                                <h2 class="section-heading-text mb-3">{{
                                        this.trans("PAGES.ABOUT.SECTIONS.ABOUT.TITLE")
                                    }}</h2>
                                <p class="section-description-text" style="white-space: pre-wrap">
                                    {{ this.trans("PAGES.ABOUT.SECTIONS.ABOUT.DESCRIPTION") }}
                                </p>
                                <div class="row company-stats mt-4">
                                    <div class="col-4"
                                         data-aos="fade-up"
                                         data-aos-duration="700"
                                         data-aos-delay="500"
                                         data-aos-once="true"
                                         data-aos-easing="ease-out-quart">
                                        <h3 class="number m-0">80+</h3>
                                        <p class="num-desc">{{
                                                this.trans("PAGES.ABOUT.SECTIONS.ABOUT.STATS.CLIENTS")
                                            }}</p>
                                    </div>
                                    <div class="col-4"
                                         data-aos="fade-up"
                                         data-aos-duration="700"
                                         data-aos-delay="1000"
                                         data-aos-once="true"
                                         data-aos-easing="ease-out-quart">
                                        <h3 class="number m-0">30+</h3>
                                        <p class="num-desc">{{
                                                this.trans("PAGES.ABOUT.SECTIONS.ABOUT.STATS.PROJECTS")
                                            }}</p>
                                    </div>
                                    <div class="col-4"
                                         data-aos="fade-up"
                                         data-aos-duration="700"
                                         data-aos-delay="1500"
                                         data-aos-once="true"
                                         data-aos-easing="ease-out-quart">
                                        <h3 class="number m-0">2015</h3>
                                        <p class="num-desc">{{
                                                this.trans("PAGES.ABOUT.SECTIONS.ABOUT.STATS.FOUNDED")
                                            }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 offset-xl-1 right-col">
                            <img src="../assets/images/triangle.png" alt="" class="w-100 right-about-company-image">
                        </div>
                    </div>
                </div>
            </section>
            <section class="contact-us">
                <div class="container p-0">
                    <div class="content text-center">
                        <h3 class="section-heading-text w-50 m-auto text-white">
                            {{ this.trans("PAGES.ABOUT.SECTIONS.CONTACT.TITLE") }}
                        </h3>
                        <router-link style="width: auto" class="m-auto" :to="`/${this.$locale}/contact`">
                            <div class="link-button mt-5">
                                <div class="color-fill">
                                    <img class="arrow-icon" src="../assets/icons/arrow.svg" alt="">
                                </div>
                                <p class="link-text text-white">{{ this.trans("BUTTONS.CONTACT_US") }}</p>
                            </div>
                        </router-link>
                    </div>
                    <img class="abstract-house d-lg-block d-none" src="../assets/images/house-drawing.png" alt="">
                </div>
            </section>
        </main>
    </div>
</template>

<script>

import $ from "jquery";

export default {
    name: "About",
    metaInfo: {
        title: "About"
    },
    mounted() {
        this.$root.setTitle(this.trans('NAV_ITEMS.ABOUT'))
        window.scrollTo(0, 0)
        document.body.classList.add('bg-light-brown')
        $(".lang-link").removeClass("router-link-active");
        $(".lang-link." + localStorage.selectedLanguage).addClass("router-link-active");
    },
    unmounted() {
        document.body.classList.remove('bg-light-brown')
    }
}
</script>

<style scoped>

</style>